<template>
  <svg
    class="absolute top-3 left-3.5"
    xmlns="http://www.w3.org/2000/svg"
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
  >
    <ellipse
      cx="9.85043"
      cy="10.3055"
      rx="7.52474"
      ry="7.49047"
      stroke="#383A47"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      opacity="0.4"
      d="M15.084 15.9042L18.0341 18.8333"
      stroke="#383A47"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
